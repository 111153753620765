import { loadScript } from '../utility';

// Add NodeList.forEach() support to IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

export default async function fetchPolyfills() {
  return loadScript('https://polyfill.io/v3/polyfill.min.js?flags=gated&features=default%2CIntersectionObserver');
}
