import globalPolyfills from './globalPolyfills';

/**
 * These are common utility functions that are unique to this site.
 * A generic utility function should go in /utilities.
 */

export {
  globalPolyfills,
};
