// Promise for IE11
// This is the first place where we need Promise.
// Even though polyfill.io will load a Promise polyfill,
// we need to include it here to ensure it is available globally.
// The promise-polyfill documentation suggests a different way 
// to import the polyfill, but that was not making it globally
// available. This method has the same result.
import Promise from 'promise-polyfill';
if (!window.Promise) window.Promise = Promise;

/**
 * Asyncronously load a script.
 * @param  {String} url  The URL of the script ot download.
 * @return {Promise}     The Promise to be resolved once the script downloads or fails.
 */
export default function loadScript(url, parent = document.head) {
  return new Promise((resolve, reject) => {
    const tag = document.createElement('script');
    tag.addEventListener('load', resolve);
    tag.addEventListener('error', reject);
    tag.src = url;
    parent.appendChild(tag);
  });
}